/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        //*Mobile Menu*//
        $( ".c-header__toggle" ).click(function(e) {
            e.preventDefault();

            $('.c-header__mobile').animate({height: 'toggle'});
        });

        // for the mobile animation

        $(".c-header__toggle").click(function(){
            $(this).toggleClass("is-active");
        });
        //// Check for cookie on pop up

        //   Amenities background image //
        var colDesktop = $('.c-amenities--background').data('desktop'),
        colMobile = $('.c-amenities--background').data('mobile');
        if(viewportWidth < 768 ){
            $('.c-amenities--background').css('background-image', 'url('+colMobile+')');
        }else{
            $('.c-amenities--background').css('background-image', 'url('+colDesktop+')');
        }


        // Testimonials Background Image
        var colDesktop2 = $('.c-testimonials--background').data('desktop'),
        colMobile2 = $('.c-testimonials--background').data('mobile');
        if(viewportWidth < 768 ){
            $('.c-testimonials--background').css('background-image', 'url('+colMobile2+')');
        }else{
            $('.c-testimonials--background').css('background-image', 'url('+colDesktop2+')');
        }

        //   Landing Offers background image //
        var colDesktop3 = $('.c-offers--background').data('desktop'),
        colMobile3 = $('.c-offers--background').data('mobile');
        if(viewportWidth < 768 ){
            $('.c-offers--background').css('background-image', 'url('+colMobile3+')');
        }else{
            $('.c-offers--background').css('background-image', 'url('+colDesktop3+')');
        }

        // Class on scroll
        var elWrap = $('.c-header');

        if (viewportWidth >= 1023) {
            $(window).scroll(function() {
                var scrollX = $(this).scrollTop();

                if (scrollX >= 10) {
                    $(elWrap).addClass('is-scrolling');
                } else {
                    $(elWrap).removeClass('is-scrolling');
                }
            });
        }

        // Class on scroll LOGO
        var elWrap3 = $('.c-logo');

        if (viewportWidth >= 1023) {
            $(window).scroll(function() {
                var scrollX3 = $(this).scrollTop();

                if (scrollX3 >= 10) {
                    $(elWrap3).addClass('scrolling');
                } else {
                    $(elWrap3).removeClass('scrolling');
                }
            });
        }

        // Class on scroll menu
        var elWrap2 = $('.c-nav__primary');

        if (viewportWidth >= 1023) {
            $(window).scroll(function() {
                var scrollX2 = $(this).scrollTop();

                if (scrollX2 >= 10) {
                    $(elWrap2).addClass('scrolled');
                } else {
                    $(elWrap2).removeClass('scrolled');
                }
            });
        }

        $(".gallery-icon a").fancybox().attr('data-fancybox', 'wp-gallery-fancybox');

        // Submenu Button placement
        $(function () {
            var children = $('.c-nav__mobile > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
            $('<span class="drop-menu">+</span>').insertAfter(children);
            $('.c-nav__mobile .drop-menu').click(function (e) {
                $(this).next().slideToggle(300);
                return false;
            });
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            // tasks to do if it is a Mobile Device
            $(function () {
                var children = $('.c-nav__primary > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
                $('<span class="drop-menu">∨</span>').insertAfter(children);
                $('.c-nav__primary .drop-menu').click(function (e) {
                    $(this).next().slideToggle(300);
                    return false;
                });
            });

        }



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // if (!Cookies.get('home_offer')) {
        //   setTimeout(function () {
        //       $.fancybox.open({
        //           src: '.popup-overlay',
        //           type: 'inline'
        //       });

        //       Cookies.set('home_offer', 'true', { expires: 0 });
        //   }, 500);
        // }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
